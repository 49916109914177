import React from "react"

const PriceSection = () => (
  <section className="section bg-gray">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <h2 className="fw-200 mb-6">Wat kost weertelaat.be?</h2>
          <p>
            Pendelaars kunnen weertelaat.be gebruiken voor slechts €1 per maand.
            Dankzij deze bijdrage kunnen we het platform onderhouden en
            verbeteren waar nodig. Heb jij nog een idee of opmerking? Laat het
            ons weten via het contactformulier.
          </p>
          <p>
            Natuurlijk bieden we jou de mogelijkheid om weertelaat.be gratis uit
            te proberen. Zo kan ook jij ondervinden dat je via weertelaat.be
            financiëel een goede zaak doet.
          </p>
        </div>

        <div className="col-md-4 mx-auto mt-7 mt-md-0">
          <div className="card card-body text-center shadow-2 hover-shadow-8 py-6">
            <p className="lead-7 fw-600 text-dark">
              <span data-bind-radio="pricing-5">€12</span>
              <span className="lead-4 align-text-top"> per jaar</span>
            </p>
            <p>
              <br />
              Alles inbegrepen, <br /> geen verborgen kosten of betalende
              extra's ;)
              <br />
              Alle stations in België
              <br />
              Alle treinen in België
              <br />
            </p>
            <br />
            <div>
              <a
                className="btn btn-round btn-outline-secondary w-200"
                href="#"
                data-bind-href="pricing-5"
                data-monthly="#monthly"
                data-yearly="#yearly"
              >
                Inschrijven
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default PriceSection
