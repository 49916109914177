import React from "react"
import Layout from "../components/Layout/Layout"
import PriceSection from "../components/PriceSection"
import RegistrationSection from "../components/RegistrationCardForm"

const ContactPage = () => (
  <Layout>
    <header className="header pt-10 pb-0">
      <div className="container text-center">
        <h1 className="display-4">Contacteer weertelaat.be</h1>
        <div className="col-md-8 offset-md-2">
        <p className="lead-2 mt-6 ">
          Heb je een vraag of bericht voor weertelaat.be? Je kan ons contacteren via het onderstaand contactformulier, sociale media of via onze chat.
        </p>
      </div>
      </div>
    </header>

    <PriceSection />
    <section className="section">
      <div className="container">
        <div className="row">
          <form className="col-lg-6 mx-auto p-6 bg-gray rounded" action="#" method="POST"
                data-form="mailer">
            <div className="alert alert-success d-on-success">We received your message and will contact you back soon.
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <input className="form-control form-control-lg" type="text" name="firstName" placeholder="Voornaam" />
              </div>
              <div className="form-group col-md-6">
                <input className="form-control form-control-lg" type="text" name="lastName" placeholder="Achternaam" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <input className="form-control form-control-lg" type="email" name="email"
                       placeholder="Email adres" />
              </div>
            </div>


            <div className="form-group">
              <textarea className="form-control form-control-lg" rows="4" placeholder="Vraag of bericht"
                        name="message" />
            </div>

            <div className="text-center">
              <button className="btn btn-lg btn-primary" type="submit">Verzenden</button>
            </div>
          </form>
        </div>

      </div>
    </section>
  </Layout>
)

export default ContactPage
